<template>
  <div>
    <el-row style="margin-top: 10px;margin-left: 10px">
      <el-button type="primary" @click="dialog.gradeAddState = true" style="margin-bottom: 10px">添加意愿</el-button>
      <el-table
          :row-style="{height: '38px'}"
          :cell-style="{padding: '0'}"
          :header-cell-style="{background:'#fafafc',color:'#606266',padding: '9px 0'}"
          :data="table.gradeList"
          border
          max-height="700"
          :default-sort="{prop: 'id', order: 'ascending'}"
          style="width: 99%">
        <el-table-column label="客户意愿度" align="center" #default="scope">
          {{scope.row.gradeName}}
          <el-badge type="primary" value="系统默认" v-if="scope.row.isDefault === 1"/>
        </el-table-column>
        <el-table-column prop="gradeColor" label="客户意度颜色" align="center">
          <template slot-scope="scope">
            <div style="height: 100%;width: 100%;display: flex;justify-content: center;align-items: center;">
              <div :style="{backgroundColor:scope.row.gradeColor,width:'100px',height:'30px'}"></div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="isValid" label="激活状态" align="center">
          <template slot-scope="scope">
            <el-switch
                @change="isValidChange(scope.row)"
                v-model="scope.row.isValid"
                :active-value="1"
                :inactive-value="0"
                :disabled="scope.row.isDefault === 1"
                active-color="#13ce66"
                inactive-color="#ff4949">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="是否系统默认参数" v-if="isSuperAdmin" align="center" #default="scope">
          <el-switch
              @change="isValidChange(scope.row)"
              v-model="scope.row.isDefault"
              :active-value="1"
              :inactive-value="0"
              active-color="#13ce66"
              inactive-color="#ff4949">
          </el-switch>
        </el-table-column>
        <el-table-column width="350" align="center" fixed="right" label="操作">
          <template slot-scope="scope">
            <el-button-group>
              <el-button type="warning" size="mini" @click="openEdit(scope.row)" v-if="scope.row.isDefault!==1">编辑</el-button>
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row style="margin-top: 5px">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-size="this.table.limit"
          layout="prev, pager, next,total, jumper, sizes"
          :total="table.total">
      </el-pagination>
    </el-row>
    <gradeAdd v-if="dialog.gradeAddState" :state="dialog.gradeAddState" @close="close"/>
    <gradeEdit v-if="dialog.gradeEditState" :grade="dialog.grade" :state="dialog.gradeEditState" @close="close"/>
  </div>
</template>

<script>
import gradeAdd from "@/pages/parameter/grade/grade-add";
import gradeEdit from "@/pages/parameter/grade/grade-edit";

export default {
  name: "grade-list",
  data() {
    return {
      tenantCrop: localStorage.getItem("tenantCrop"),
      table: {
        gradeList: [],
        page: 1,
        limit: 100,
        total: 0,
      },
      dialog: {
        gradeAddState: false,
        gradeEditState: false,
        grade: {},
      },
      isSuperAdmin: localStorage.getItem("tenantCrop") === '2a31c23a-c178-441614928053489'
    }
  },
  components: {
    gradeAdd, gradeEdit,
  },
  created() {
    this.queryGradeList();
  },
  methods: {
    search() {
      this.table.page = 1;
      this.queryGradeList();
    },
    //查询客户列表
    queryGradeList: function () {
      this.$axios({
        method: "GET",
        url: "/grade/gradeList",
        params: {
          page: this.table.page,
          limit: this.table.limit,
          tenantCrop: this.tenantCrop,
        }
      }).then(response => {
        this.table.total = response.data.data.total;
        this.table.gradeList = response.data.data.list;
        console.log(this.table.gradeList)
      })
    },
    isValidChange: function (value) {
      this.$axios({
        method: "PUT",
        url: '/grade/updateGrade',
        params: {
          "id": value.id,
          "isValid": value.isValid,
          "tenantCrop": this.tenantCrop,
          isDefault: value.isDefault,
        }
      }).then(response => {
        let isValidState = response.data.code === 200;
        this.$message({
          message: isValidState ? "修改成功" : response.data.msg,
          type: isValidState ? "success" : 'error'
        });
        this.queryGradeList()
      })
    },
    //打开编辑店铺
    openEdit(data) {
      this.dialog.gradeEditState = true
      this.dialog.grade = data
    },
    //更改每页长度
    handleSizeChange: function (val) {
      this.table.limit = val;
      this.queryGradeList();
    },
    handleCurrentChange(val) {
      this.table.page = val
      this.queryGradeList()
    },
    close: function (state) {
      this.dialog.gradeAddState = state;
      this.dialog.gradeEditState = state;
      this.queryGradeList();
    }
  }
}
</script>

<style scoped>

</style>